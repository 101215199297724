<div [ngClass]="{ center: fullPanel }">
  <div class="lds-spinner" [ngClass]="{ 'half-size': !fullPanel }">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
