import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'signpost-dyslexia-quest',
  standalone: true,
  template: '',
})
export class DyslexiaQuestComponent implements OnInit {
  ngOnInit(): void {
    this.navigateToGame();
  }

  navigateToGame() {
    // replace the current URL state so back button in game page goes back to signpost, not this page
    history.replaceState({}, "", "/");
    window.location.href = '/learn/dyslexiaquest/';
  }
}
