import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'signpost-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService) { }

  ngOnInit() {
    // Clear localStorage
    localStorage.clear();
    sessionStorage.clear();
    this.oidcSecurityService.revokeAccessToken();
    this.oidcSecurityService.revokeRefreshToken();

    // Post a message to the parent window to indicate game logout
    window.parent.postMessage('signpost_logout', '*');

    // Define the message handler function
    const handleMessage = (evt: MessageEvent): void => {
      // Check if the event or its data is undefined, or if the data is not a string
      // (this ignores buggy encrypt messages in Chrome iOS)
      if (!evt || !evt.data || typeof evt.data !== 'string') {
        return;
      }

      // Abort if the request doesn't come from a valid origin
      if (!evt.origin.endsWith('nessy.com')) return;

      // Handle the "logout" message
      if (evt.data === 'logout') {
        localStorage.clear();
        window.parent.postMessage('signpost_logout', '*');
      }
    };

    // Add the event listener for message events
    window.addEventListener('message', handleMessage);
  }
}
