import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { ReadingSpellingComponent } from './games/reading-spelling/reading-spelling.component';
import { HairyReadingComponent } from './games/hairy-reading/hairy-reading.component';
import { NumberSenseComponent } from './games/number-sense/number-sense.component';
import { NessyFingersComponent } from './games/nessy-fingers/nessy-fingers.component';
import { JungleTownComponent } from './games/jungle-town/jungle-town.component';
import { DyslexiaQuestComponent } from './games/dyslexia-quest/dyslexia-quest.component';
import { WritingBeachComponent } from './games/writing-beach/writing-beach.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./signpost/signpost.module').then((m) => m.SignpostModule),
    title: 'Choose a Nessy program',
    canActivate: [AuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    title: 'Logout',
  },
  {
    path: 'readingspelling',
    component: ReadingSpellingComponent,
    title: 'Reading and Spelling',
    canActivate: [AuthGuard],
  },
  {
    path: 'hreading',
    component: HairyReadingComponent,
    title: 'H Reading',
    canActivate: [AuthGuard],
  },
  {
    path: 'numbersense',
    component: NumberSenseComponent,
    title: 'Number Sense',
    canActivate: [AuthGuard],
  },
  {
    path: 'nessyfingers',
    component: NessyFingersComponent,
    title: 'Nessy Fingers',
    canActivate: [AuthGuard],
  },
  {
    path: 'jungletown',
    component: JungleTownComponent,
    title: 'Jungle Town',
    canActivate: [AuthGuard],
  },
  {
    path: 'dyslexiaquest',
    component: DyslexiaQuestComponent,
    title: 'Dyslexia Quest',
    canActivate: [AuthGuard],
  },
  {
    path: 'writingbeach',
    component: WritingBeachComponent,
    title: 'Writing Beach',
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
