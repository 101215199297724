import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'signpost-number-sense',
  standalone: true,
  template: '',
})
export class NumberSenseComponent implements OnInit {
  ngOnInit(): void {
    this.navigateToGame();
  }

  navigateToGame() {
    // replace the current URL state so back button in game page goes back to signpost, not this page
    history.replaceState({}, "", "/");
    window.location.href = '/learn/numbersense/';
  }
}
