import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { TokenInterceptor } from './token.interceptor';

import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { CustomStorageService } from './custom.storage.service';


@NgModule({
  imports: [
    CommonModule,
    AuthModule.forRoot({
      config: {
        authority: environment.settings.authAuthority,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.settings.clientId,
        scope: 'openid offline_access learning',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
      },
    }),
  ],
  exports: [AuthModule],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: AbstractSecurityStorage, useClass: CustomStorageService },
  ],
})
export class AuthConfigModule {}
