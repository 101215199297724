import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, timeout } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';

const ERROR_CYCLE_ONCE = 'signpostErrorCycleOneAttempt';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      timeout(10000),
      switchMap(isAuthenticated => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('fromNessyBadgeLogin') === 'true') {
          localStorage.clear();
        }
        if (isAuthenticated) {
          return this.checkAndHandleRoute();
        } else {
          return this.oidcSecurityService.checkAuth().pipe(
            switchMap(res => {
              if (res.isAuthenticated) {
                return this.checkAndHandleRoute(res);
              } else {
                this.goToIdentityLoginPage();
                return of(false);
              }
            }),
            catchError(() => {
              if (!localStorage.getItem(ERROR_CYCLE_ONCE)) {
                localStorage.setItem(ERROR_CYCLE_ONCE, 'done');
                this.goToIdentityLoginPage();
              } else {
                localStorage.removeItem(ERROR_CYCLE_ONCE);
                console.error(
                  'Identity Server failed to confirm your access to this application'
                );
                this.router.navigateByUrl('error');
              }
              return of(false);
            })
          );
        }
      }),
      catchError(() => of(false))
    );
  }

  private checkAndHandleRoute(authResult?: LoginResponse): Observable<boolean> {
    if (authResult && !this.authService.roleIncludesStudent(authResult)) {
      this.authService.logout();
      return of(false);
    }

    // Get landing URL from local storage
    const requestedRoute = localStorage.getItem('REQUESTED_ROUTE');
    if (requestedRoute) {
      localStorage.removeItem('REQUESTED_ROUTE');
      this.router.navigateByUrl(requestedRoute);
    }

    return of(true);
  }

  private goToIdentityLoginPage(): void {
    this.authService.goToIdentityLoginPage();
  }
}

